// src/firebaseInit.js
import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDRcZEaMuC166oe9aSi5IozAdt3s98m-1E",
  authDomain: "operative-975e2.firebaseapp.com",
  projectId: "operative-975e2",
  storageBucket: "operative-975e2.appspot.com",
  messagingSenderId: "1064174049465",
  appId: "1:1064174049465:web:74727143cbbdfae2d66368",
  measurementId: "${config.measurementId}",
  databaseURL: "https://operative-975e2.firebaseio.com"
};

// // Initialize Firebase
// export default firebase.initializeApp(firebaseConfig);

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // Use the existing instance if already initialized
}

export default firebase;